.App {
  /*text-align: center;*/
}

.App {
  background-color: #e6e6e6;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.App-menu-bar {
  position: relative;
}

.App-main {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

